import { FC } from 'react';
import { CustomNavigationMeta } from 'app/routes';
import * as Styled from './CustomNav.styled';

type CustomNavProps = {
  navigationMeta: CustomNavigationMeta;
};

const CustomNav: FC<CustomNavProps> = ({ navigationMeta }) => {
  const { logoAltText, logoImagePath, logoAnchorUrl, coBranded } = navigationMeta;
  return (
    <Styled.NavWrapper>
      <Styled.BrandingWrapper>
        <Styled.LogoWrapper position="left">
          <a href={logoAnchorUrl} style={{ display: 'flex' }} role="link">
            <img
              id="custom-logo-nav"
              src={logoImagePath}
              aria-label={logoAltText}
              style={{ maxHeight: `${Styled.NAV_HEIGHT}px` }}
            />
          </a>
        </Styled.LogoWrapper>
        <Styled.LogoWrapper position="right">
          {coBranded && (
            <a href="https://www.service.nsw.gov.au" style={{ display: 'flex' }} role="link">
              <img
                id="snsw-logo-nav"
                src={'./sbr-assets/agency-logos/snsw.png'}
                aria-label="Service NSW Logo"
                style={{ maxHeight: `${Styled.NAV_HEIGHT}px` }}
              />
            </a>
          )}
        </Styled.LogoWrapper>
      </Styled.BrandingWrapper>
    </Styled.NavWrapper>
  );
};

export default CustomNav;
