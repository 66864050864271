import styled from 'styled-components';
import config from 'app/theme/styles/global';

export const NAV_HEIGHT = 50;

const mobileBreakpoint = `${config.breakpoints.small.value}px`;
const tabletBreakpoint = `${config.breakpoints.large.value}px`;

export const LogoWrapper = styled.div<{ position: 'left' | 'right' }>`
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: ${({ position }) => (position === 'left' ? 'flex-start' : 'flex-end')};
  margin-right: ${({ position }) => (position === 'left' ? '10px' : '0')};
  @media (max-width: ${mobileBreakpoint}) {
    display: ${({ position }) => (position === 'right' ? 'none' : 'flex')};
    justify-content: center;
  }
`;

export const BrandingWrapper = styled.div`
  max-width: ${tabletBreakpoint};
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  padding: 30px;
  max-height: ${NAV_HEIGHT + 15}px;
`;

export const NavWrapper = styled.nav`
  display: flex;
  border-bottom: 1px solid #eaeff1;
  box-shadow: #eaeff1 0px 1px 5px;
  width: 100%;
  justify-content: center;
`;
