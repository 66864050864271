import styled from 'styled-components';

export const AppContainer = styled.div`
  min-height: calc(100vh - 250px) !important;
`;

export const auxId = 'header_global_auxiliary';
export const navId = 'header_global';
export const headerIdAttribute = 'data-ga4-section-id';

export const GlobalNavStyles = styled.div<{ customNav: boolean }>`
  ${(props) =>
    props.customNav
      ? `div[${headerIdAttribute}='${auxId}'] {
    display: block !important;
  }
  div[${headerIdAttribute}='${navId}'] {
    display: none !important;
  }`
      : ''}
`;
