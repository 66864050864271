/* eslint-disable react/display-name */
import { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '@snsw-gel/react';
import useEvent from 'app/hooks/useEvent';

const Message = styled.p`
  margin-right: 0.5em;
`;

const CommonInfoModal = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState('Confirm');
  const [onConfirmHandler, setOnConfirmHandler] = useState<() => void>();
  const [closeButtonText, setCloseButtonText] = useState('Close');
  const [onCloseHandler, setOnCloseHandler] = useState<() => void>();

  const closeModal = () => {
    onCloseHandler && onCloseHandler();
    setOpenModal(false);
  };

  const confirmModal = () => {
    onConfirmHandler && onConfirmHandler();
    setOpenModal(false);
  };

  /* istanbul ignore next */
  useEvent('OPEN_INFO_MODAL', (event) => {
    const eventTitle = event.detail.title;
    setTitle(eventTitle);

    const eventMessage = event.detail.message;
    setMessage(eventMessage);

    const closeText = event.detail?.buttons?.close?.text;
    const closeHandler = event.detail?.buttons?.close?.handler;
    const confirmText = event.detail?.buttons?.confirm?.text;
    const confirmHandler = event.detail?.buttons?.confirm?.handler;
    const disableClose = event.detail?.disableClose;
    if (closeText) setCloseButtonText(closeText);
    if (closeHandler) setOnCloseHandler(() => () => closeHandler());
    if (confirmText) setConfirmButtonText(confirmText);
    if (confirmHandler) setOnConfirmHandler(() => () => confirmHandler());
    if (disableClose) setDisableCloseButton(disableClose);
    setOpenModal(true);
  });

  return (
    <>
      {!!openModal && (
        <Modal
          title={title}
          buttons={
            disableCloseButton
              ? [{ text: confirmButtonText, onClick: confirmModal }]
              : [
                  { text: confirmButtonText, onClick: confirmModal },
                  { text: closeButtonText, onClick: closeModal },
                ]
          }
        >
          <div>
            <Message>{message}</Message>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CommonInfoModal;
